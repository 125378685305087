"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateTokenPrice = exports.tokenPriceSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.tokenPriceSlice = toolkit_1.createSlice({
    name: "tokenPrice",
    initialState,
    reducers: {
        updateTokenPrice: (state, action) => {
            return action.payload;
        },
    },
});
exports.updateTokenPrice = exports.tokenPriceSlice.actions.updateTokenPrice;
exports.default = exports.tokenPriceSlice.reducer;
