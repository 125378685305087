"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateHdWallets = exports.addNewHdWallet = exports.resetMainHdWallet = exports.hdWalletsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.hdWalletsSlice = toolkit_1.createSlice({
    name: "hdWallets",
    initialState,
    reducers: {
        resetMainHdWallet: (state, action) => {
            const arr = state.concat();
            arr[0] = action.payload;
            return arr;
        },
        addNewHdWallet: (state, action) => {
            return [...state, action.payload];
        },
        updateHdWallets: (state, action) => {
            return action.payload || [];
        },
    },
});
_a = exports.hdWalletsSlice.actions, exports.resetMainHdWallet = _a.resetMainHdWallet, exports.addNewHdWallet = _a.addNewHdWallet, exports.updateHdWallets = _a.updateHdWallets;
exports.default = exports.hdWalletsSlice.reducer;
