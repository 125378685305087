"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_2 = require("react");
const react_redux_1 = require("react-redux");
const token_1 = require("../../apis/token");
const notification_1 = require("../../redux/features/notification");
const identity_1 = require("../../utils/identity");
const FaucetBtn = (props) => {
    const [loading, setLoading] = react_2.useState("");
    const btn = react_1.useRef(null);
    const [claimed, setClaimed] = react_2.useState(null);
    const dispatch = react_redux_1.useDispatch();
    react_1.useEffect(() => {
        let _isMounted = true;
        const theOne = identity_1.getSelectedAccount();
        if (theOne === null || theOne === void 0 ? void 0 : theOne.principal) {
            token_1.isClaimed(props.canisterId, theOne.principal).then((res) => {
                if (_isMounted)
                    setClaimed(res ? 1 : 0);
            });
        }
        else {
            setClaimed(1);
        }
        return () => {
            _isMounted = false;
        };
    }, [props.canisterId]);
    const claim = async () => {
        setLoading("Claiming");
        token_1.claimToken(props.canisterId)
            .then((res) => {
            if (res) {
                props.updateBal();
                dispatch(notification_1.addNotification({ type: "done", content: "Token Claimed." }));
                if (btn.current) {
                    setLoading("Done");
                    setClaimed(1);
                }
                setTimeout(() => {
                    if (btn.current)
                        setLoading("");
                }, 1200);
            }
            else {
                dispatch(notification_1.addNotification({
                    type: "failed",
                    content: "Failed to claim tokens.",
                }));
                if (btn.current)
                    setLoading("Error: Claimed");
                setTimeout(() => {
                    if (btn.current)
                        setLoading("");
                }, 2000);
            }
        })
            .catch((err) => {
            console.log(err);
            dispatch(notification_1.addNotification({
                type: "failed",
                content: "Failed to claim tokens.",
            }));
            if (btn.current)
                setLoading("Failed");
            setTimeout(() => {
                if (btn.current)
                    setLoading("");
            }, 1200);
        });
    };
    if (claimed === 0)
        return null;
    return (jsx_runtime_1.jsxs("button", Object.assign({ ref: btn, className: "FaucetBtn", onClick: claim, disabled: loading || claimed ? true : false }, { children: [loading, !loading && claimed ? "Claimed" : "", !loading && !claimed ? "Claim" : ""] }), void 0));
};
exports.default = FaucetBtn;
