"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDfinityIdentity = exports.dfinityIdentitySlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    identity: null,
    principal: "",
    publicKey: "",
    keys: ["", ""],
};
exports.dfinityIdentitySlice = toolkit_1.createSlice({
    name: "dfinityIdentity",
    initialState,
    reducers: {
        updateDfinityIdentity: (state, action) => {
            return action.payload;
        },
    },
});
exports.updateDfinityIdentity = exports.dfinityIdentitySlice.actions.updateDfinityIdentity;
exports.default = exports.dfinityIdentitySlice.reducer;
