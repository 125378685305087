"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLoggedIn = exports.loggedInSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = false;
exports.loggedInSlice = toolkit_1.createSlice({
    name: "loggedIn",
    initialState,
    reducers: {
        updateLoggedIn: (state, action) => {
            return action.payload;
        },
    },
});
exports.updateLoggedIn = exports.loggedInSlice.actions.updateLoggedIn;
exports.default = exports.loggedInSlice.reducer;
