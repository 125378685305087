"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateTokenInfo = exports.tokenInfoSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.tokenInfoSlice = toolkit_1.createSlice({
    name: "tokenInfo",
    initialState,
    reducers: {
        updateTokenInfo: (state, action) => {
            return action.payload;
        },
        // addTokenInfo: (state, action: { type: string; payload: Token }) => { // it could mess up the order and length
        //   let arr = state
        //     .filter((i) => i.canisterId !== action.payload.canisterId)
        //     .concat(action.payload);
        //   return arr;
        // },
    },
});
exports.updateTokenInfo = exports.tokenInfoSlice.actions.updateTokenInfo;
exports.default = exports.tokenInfoSlice.reducer;
