"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.addTokenMetaData = exports.updateTokenMetaData = exports.tokenMetaDataSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.tokenMetaDataSlice = toolkit_1.createSlice({
    name: "tokenMetaData",
    initialState,
    reducers: {
        updateTokenMetaData: (state, action) => {
            return action.payload;
        },
        addTokenMetaData: (state, action) => {
            let arr = state
                .filter((i) => i.canisterId !== action.payload.canisterId)
                .concat(action.payload);
            return arr;
        },
    },
});
_a = exports.tokenMetaDataSlice.actions, exports.updateTokenMetaData = _a.updateTokenMetaData, exports.addTokenMetaData = _a.addTokenMetaData;
exports.default = exports.tokenMetaDataSlice.reducer;
