"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateICPBal = exports.ICPBalSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = "";
exports.ICPBalSlice = toolkit_1.createSlice({
    name: "ICPBal",
    initialState,
    reducers: {
        updateICPBal: (state, action) => {
            return action.payload;
        },
    },
});
exports.updateICPBal = exports.ICPBalSlice.actions.updateICPBal;
exports.default = exports.ICPBalSlice.reducer;
