"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDfinityMainAccount = exports.updateDfinitySubAccounts = exports.addNewDfinitySubAccount = exports.dfinitySubAccountsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.dfinitySubAccountsSlice = toolkit_1.createSlice({
    name: "dfinitySubAccounts",
    initialState,
    reducers: {
        addNewDfinitySubAccount: (state, action) => {
            localStorage.setItem("DSAs", JSON.stringify([...state, action.payload]));
            return [...state, action.payload];
        },
        updateDfinitySubAccounts: (state, action) => {
            localStorage.setItem("DSAs", JSON.stringify(action.payload || []));
            return action.payload || [];
        },
        updateDfinityMainAccount: (state, action) => {
            let arr = state.concat();
            arr.splice(0, 1, action.payload);
            localStorage.setItem("DSAs", JSON.stringify(arr));
            return arr;
        },
    },
});
_a = exports.dfinitySubAccountsSlice.actions, exports.addNewDfinitySubAccount = _a.addNewDfinitySubAccount, exports.updateDfinitySubAccounts = _a.updateDfinitySubAccounts, exports.updateDfinityMainAccount = _a.updateDfinityMainAccount;
exports.default = exports.dfinitySubAccountsSlice.reducer;
