"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.addLpInfo = exports.updateLpInfo = exports.lpInfoSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.lpInfoSlice = toolkit_1.createSlice({
    name: "lpInfo",
    initialState,
    reducers: {
        updateLpInfo: (state, action) => {
            return action.payload;
        },
        addLpInfo: (state, action) => {
            let arr = state
                .filter((i) => i.id !== action.payload.id)
                .concat(action.payload);
            return arr;
        },
    },
});
_a = exports.lpInfoSlice.actions, exports.updateLpInfo = _a.updateLpInfo, exports.addLpInfo = _a.addLpInfo;
exports.default = exports.lpInfoSlice.reducer;
