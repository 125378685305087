"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePassword = exports.passwordSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = "";
exports.passwordSlice = toolkit_1.createSlice({
    name: "password",
    initialState,
    reducers: {
        updatePassword: (state, action) => {
            return action.payload;
        },
    },
});
exports.updatePassword = exports.passwordSlice.actions.updatePassword;
exports.default = exports.passwordSlice.reducer;
