"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateImportedAccounts = exports.addNewImportedAccount = exports.importedAccountsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.importedAccountsSlice = toolkit_1.createSlice({
    name: "importedAccounts",
    initialState,
    reducers: {
        addNewImportedAccount: (state, action) => {
            return [...state, action.payload];
        },
        updateImportedAccounts: (state, action) => {
            return action.payload || [];
        },
    },
});
_a = exports.importedAccountsSlice.actions, exports.addNewImportedAccount = _a.addNewImportedAccount, exports.updateImportedAccounts = _a.updateImportedAccounts;
exports.default = exports.importedAccountsSlice.reducer;
