"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.addNotification = exports.updateNotification = exports.notificationSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.notificationSlice = toolkit_1.createSlice({
    name: "notification",
    initialState,
    reducers: {
        updateNotification: (state, action) => {
            return action.payload;
        },
        addNotification: (state, action) => {
            let arr = state.concat(action.payload);
            return arr;
        },
    },
});
_a = exports.notificationSlice.actions, exports.updateNotification = _a.updateNotification, exports.addNotification = _a.addNotification;
exports.default = exports.notificationSlice.reducer;
