"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSelected = exports.selectedSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = "";
exports.selectedSlice = toolkit_1.createSlice({
    name: "selected",
    initialState,
    reducers: {
        updateSelected: (state, action) => {
            localStorage.setItem("selected", action.payload);
            return action.payload;
        },
    },
});
exports.updateSelected = exports.selectedSlice.actions.updateSelected;
exports.default = exports.selectedSlice.reducer;
