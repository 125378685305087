"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const auth_client_1 = require("@dfinity/auth-client");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const dfinityIdentity_1 = require("../../redux/features/dfinityIdentity");
const dfinitySubAccounts_1 = require("../../redux/features/dfinitySubAccounts");
const hdWallets_1 = require("../../redux/features/hdWallets");
const importedAccounts_1 = require("../../redux/features/importedAccounts");
const loggedIn_1 = require("../../redux/features/loggedIn");
const password_1 = require("../../redux/features/password");
const selected_1 = require("../../redux/features/selected");
const selectedIndex_1 = require("../../redux/features/selectedIndex");
const LogoutBtn = (props) => {
    const { dfinityIdentity } = react_redux_1.useSelector((state) => state);
    const dispatch = react_redux_1.useDispatch();
    const logout = () => {
        if (dfinityIdentity && dfinityIdentity.principal) {
            auth_client_1.AuthClient.create().then((res) => res.logout());
        }
        // reset all but tokens
        localStorage.removeItem("selected");
        localStorage.removeItem("index");
        localStorage.removeItem("DSAs");
        localStorage.removeItem("password");
        localStorage.removeItem("Wallets");
        localStorage.removeItem("Imported");
        localStorage.removeItem("mnemonic");
        props.history.push("/");
        dispatch(loggedIn_1.updateLoggedIn(false));
        dispatch(password_1.updatePassword(""));
        dispatch(selected_1.updateSelected(""));
        dispatch(selectedIndex_1.updateSelectedIndex(-1));
        dispatch(hdWallets_1.updateHdWallets([]));
        dispatch(dfinityIdentity_1.updateDfinityIdentity({
            identity: null,
            principal: "",
            publicKey: "",
            keys: ["", ""],
        }));
        dispatch(dfinitySubAccounts_1.updateDfinitySubAccounts([]));
        dispatch(importedAccounts_1.updateImportedAccounts([]));
    };
    return (jsx_runtime_1.jsx("button", Object.assign({ className: "LogoutBtn", onClick: logout }, { children: props.children }), void 0));
};
exports.default = react_router_dom_1.withRouter(LogoutBtn);
