"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const token_1 = require("../../apis/token");
const notification_1 = require("../../redux/features/notification");
const HarvestBtn = (props) => {
    const [loading, setLoading] = react_1.useState("");
    const dom = react_1.useRef(null);
    const dispatch = react_redux_1.useDispatch();
    const submit = async () => {
        setLoading("Harvesting");
        try {
            await token_1.harvestReward(props.poolId);
            props.updateCard();
            dispatch(notification_1.addNotification({ type: "done", content: "Rewards harvested." }));
            if (dom.current)
                setLoading("Done");
        }
        catch (err) {
            console.log(err);
            dispatch(notification_1.addNotification({
                type: "failed",
                content: "Failed to harvest rewards.",
            }));
            if (dom.current)
                setLoading("Failed");
        }
        setTimeout(() => {
            if (dom.current)
                setLoading("");
        }, 1200);
    };
    return (jsx_runtime_1.jsx("button", Object.assign({ ref: dom, onClick: submit, disabled: loading ? true : false }, { children: loading || "Harvest" }), void 0));
};
exports.default = HarvestBtn;
