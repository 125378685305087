"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSelectedIndex = exports.selectedIndexSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = -1;
exports.selectedIndexSlice = toolkit_1.createSlice({
    name: "selectedIndex",
    initialState,
    reducers: {
        updateSelectedIndex: (state, action) => {
            localStorage.setItem("index", action.payload.toString());
            return action.payload;
        },
    },
});
exports.updateSelectedIndex = exports.selectedIndexSlice.actions.updateSelectedIndex;
exports.default = exports.selectedIndexSlice.reducer;
