"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePlugWallet = exports.plugWalletSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    plugWallet: null,
    principal: "",
    publicKey: "",
    keys: ["", ""],
};
exports.plugWalletSlice = toolkit_1.createSlice({
    name: "plugWallet",
    initialState,
    reducers: {
        updatePlugWallet: (state, action) => {
            return action.payload;
        },
    },
});
exports.updatePlugWallet = exports.plugWalletSlice.actions.updatePlugWallet;
exports.default = exports.plugWalletSlice.reducer;
