"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.addLpPrice = exports.updateLpPrice = exports.lpPriceSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.lpPriceSlice = toolkit_1.createSlice({
    name: "lpPrice",
    initialState,
    reducers: {
        updateLpPrice: (state, action) => {
            return action.payload;
        },
        addLpPrice: (state, action) => {
            // after a lp created (lp has to contain WICPT)
            let arr = state.concat(action.payload);
            return arr;
        },
    },
});
_a = exports.lpPriceSlice.actions, exports.updateLpPrice = _a.updateLpPrice, exports.addLpPrice = _a.addLpPrice;
exports.default = exports.lpPriceSlice.reducer;
