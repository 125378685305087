export const idlFactory = ({ IDL }) => {
  const Operation__1 = IDL.Variant({
    lpTransfer: IDL.Null,
    lpApprove: IDL.Null,
    withdraw: IDL.Null,
    tokenTransfer: IDL.Null,
    swap: IDL.Null,
    addLiquidity: IDL.Null,
    createPair: IDL.Null,
    deposit: IDL.Null,
    removeLiquidity: IDL.Null,
    tokenApprove: IDL.Null,
  });
  const Time = IDL.Int;
  const Operation = IDL.Variant({
    lpTransfer: IDL.Null,
    lpApprove: IDL.Null,
    withdraw: IDL.Null,
    tokenTransfer: IDL.Null,
    swap: IDL.Null,
    addLiquidity: IDL.Null,
    createPair: IDL.Null,
    deposit: IDL.Null,
    removeLiquidity: IDL.Null,
    tokenApprove: IDL.Null,
  });
  const OpRecord = IDL.Record({
    op: Operation,
    to: IDL.Principal,
    tokenId: IDL.Text,
    from: IDL.Principal,
    amount0: IDL.Nat,
    amount1: IDL.Nat,
    timestamp: Time,
    caller: IDL.Principal,
    index: IDL.Nat,
    amount: IDL.Nat,
  });
  const Storage = IDL.Service({
    addRecord: IDL.Func(
      [
        IDL.Principal,
        Operation__1,
        IDL.Text,
        IDL.Principal,
        IDL.Principal,
        IDL.Nat,
        IDL.Nat,
        IDL.Nat,
        Time,
      ],
      [IDL.Nat],
      []
    ),
    allHistory: IDL.Func([], [IDL.Vec(OpRecord)], ["query"]),
    clearData: IDL.Func([], [IDL.Bool], []),
    dswapCanisterId: IDL.Func([], [IDL.Principal], ["query"]),
    getCycles: IDL.Func([], [IDL.Nat], ["query"]),
    getHistory: IDL.Func([IDL.Nat, IDL.Nat], [IDL.Vec(OpRecord)], ["query"]),
    getHistoryByAccount: IDL.Func(
      [IDL.Principal],
      [IDL.Opt(IDL.Vec(OpRecord))],
      ["query"]
    ),
    getHistoryByIndex: IDL.Func([IDL.Nat], [OpRecord], ["query"]),
    getUserHistory: IDL.Func(
      [IDL.Principal, IDL.Nat, IDL.Nat],
      [IDL.Vec(OpRecord)],
      ["query"]
    ),
    getUserOpAmount: IDL.Func([IDL.Principal], [IDL.Nat], ["query"]),
    opAmount: IDL.Func([], [IDL.Nat], ["query"]),
    owner: IDL.Func([], [IDL.Principal], ["query"]),
    setDSwapCanisterId: IDL.Func([IDL.Principal], [IDL.Bool], []),
  });
  return Storage;
};
export const init = ({ IDL }) => {
  return [IDL.Principal];
};
export default idlFactory;
