"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const whitelist = [process.env.REACT_APP_LEDGER_CANISTER_ID, process.env.REACT_APP_CMINTING_CANISTER_ID, process.env.REACT_APP_TOKEN_REGISTRY, process.env.REACT_APP_DFC_CANISTER_ID, process.env.REACT_APP_WICPT_CANISTER_ID, process.env.REACT_APP_FAUCET_ID, process.env.REACT_APP_DSWAP_MAIN, process.env.REACT_APP_DSWAP_STORAGE, process.env.REACT_APP_STAKING_ID];
const host = process.env.REACT_APP_HOST || '';
class PlugWallet {
    static async verifyConnectionAndAgent() {
        // @ts-ignore
        const connected = await window.ic.plug.isConnected();
        // @ts-ignore
        // @ts-ignore
        if (!connected)
            await window.ic.plug.requestConnect({ whitelist: this.whitelist, host });
        // @ts-ignore
        // @ts-ignore
        if (connected && !window.ic.plug.agent) {
            // @ts-ignore
            await window.ic.plug.createAgent({ whitelist: this.whitelist, host });
        }
    }
    static async updateAgentWhitelist(canisterId) {
        if (this.whitelist.includes(canisterId)) {
            return;
        }
        this.whitelist.push(canisterId);
        // @ts-ignore
        return await window.ic.plug.createAgent({ whitelist: this.whitelist, host });
    }
}
exports.default = PlugWallet;
// @ts-ignore
PlugWallet.whitelist = whitelist;
PlugWallet.host = host;
